import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button, PageHeader, Card, Typography, Skeleton } from "antd";
import axios from "axios";
import Slider from "react-slick";
import { ReactComponent as Step1 } from "../assets/img/step1_4.svg";
import { ReactComponent as Step2 } from "../assets/img/step1_2.svg";
import { ReactComponent as Step3 } from "../assets/img/step3.svg";
import { ReactComponent as Step4 } from "../assets/img/step4.svg";
import { ReactComponent as Step5 } from "../assets/img/step5.svg";
import banner1 from "../assets/img/01_Banner_AMARELO.jpg";
import banner2 from "../assets/img/02_Banner_AZUL.jpg";
import banner3 from "../assets/img/03_Banner_ROSA.jpg";
import banner4 from "../assets/img/04_Banner_VERDE.jpg";
import { API_URL } from "../constants";

const { Meta } = Card;
const { Title } = Typography;

const Description = ({ age, family, school }) => {
  return (
    <div style={{ marginBottom: 32 }}>
      <p>
        Tenho <strong>{age} anos</strong>
      </p>
      <p>
        Moro <strong>{family}</strong>
      </p>
      <p>
        Estou cursando o <strong>{school.replace("cursando o", "")}</strong>
      </p>
    </div>
  );
};

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });

    //axios.get('https://reconstruir.herokuapp.com/api/child')
    axios
      .get(`${API_URL}child`)
      .then((response) => {
        // handle success
        console.log("response >>> ", response);
        this.setState({
          loading: false,
          list: response.data || [],
        });
      })
      .catch((error) => {
        // handle error
        console.log("Error >>> ", error);
      });
  }

  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: false,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 3000,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <React.Fragment>
        {/* <div className="banner"></div> */}
        <Slider {...settings}>
          <img src={banner1} alt="banner 1" />
          <img src={banner2} alt="banner 2" />
          <img src={banner3} alt="banner 3" />
          <img src={banner4} alt="banner 4" />
        </Slider>
        <div className="allStepsBanner">
          <div className="container">
            <Title level={2} align="center" className="step-title">
              Como funciona o apadrinhamento de crianças
            </Title>
            <Row type="flex" justify="space-around" gutter={32}>
              <Col md={4} align="center" className="step-desc">
                <Step1 width="80" />
                <p className="desc">1. Selecione uma criança para apadrinhar</p>
              </Col>
              <Col sm={24} md={4} align="center" className="step-desc">
                <Step2 width="80" />
                <p className="desc">
                  2. Conheça um pouco sobre a história da criança
                </p>
              </Col>
              <Col sm={24} md={4} align="center" className="step-desc">
                <Step3 width="80" />
                <p className="desc">
                  3. Defina o valor da sua contribuição mensal e a forma de
                  pagamento
                </p>
              </Col>
              <Col sm={24} md={4} align="center" className="step-desc">
                <Step4 width="80" />
                <p className="desc">4. Preencha o formulário</p>
              </Col>
              <Col sm={24} md={4} align="center" className="step-desc">
                <Step5 width="80" />
                <p className="desc">5. Entraremos em contato com você</p>
              </Col>
            </Row>
          </div>
        </div>
        <div className="container">
          <PageHeader
            //onBack={() => null}
            title="Todas as crianças do projeto"
            subTitle="Seleciona uma para apadrinhar"
          />
          <Row type="flex" justify="start" gutter={32}>
            {this.state.loading && <Skeleton />}
            {!this.state.loading &&
              this.state.list.map((kid, i) => (
                <Col sm={24} md={8} key={i}>
                  <Card cover={<img alt={kid.name} src={kid.profile_photo} />}>
                    <Meta
                      title={<Title level={3}>{kid.first_name}</Title>}
                      description={
                        <Description
                          age={kid.age}
                          family={kid.family_description}
                          school={kid.school_status}
                        />
                      }
                    />
                    {(kid.total_sponsorships > 2 && (
                      <Button type="secondary" block disabled>
                        Consegui o meu padrinho
                      </Button>
                    )) || (
                      <Button type="primary" block>
                        <Link to={`/crianca/${kid.id}`}>Mais detalhes</Link>
                      </Button>
                    )}
                  </Card>
                </Col>
              ))}
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
