import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "./views/Home";
import Kid from "./views/Kid";
import Checkout from "./views/Checkout";

import { ReactComponent as Instagram } from "./assets/img/instagram.svg";
import { ReactComponent as Facebook } from "./assets/img/facebook.svg";
import { ReactComponent as Linkedin } from "./assets/img/linkedin.svg";
import "./App.css";
import ScrollToTop from "./utils/scrollTop";
import { AppFooter } from "./assets/style/App";
import {
  LOGO,
  LINK_SITE,
  LINK_INSTAGRAM,
  LINK_FACEBOOK,
  LINK_LINKEDIN,
} from "./constants.js";
import "moment/locale/pt-br";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <div className="links">
            <a href={LINK_INSTAGRAM} target="_blank">
              <Instagram width={25} />
            </a>
            <a href={LINK_FACEBOOK} target="_blank">
              <Facebook width={25} />
            </a>
            <a href={LINK_LINKEDIN} target="_blank">
              <Linkedin width={25} />
            </a>
          </div>
          <div className="App-logo">
            <img src={LOGO} alt="logo" width={80} />
          </div>
          <div className="links" style={{ textAlign: "right" }}>
            <a href={LINK_SITE} target="_blank">
              Conheca o nosso site
            </a>
          </div>
        </header>
        <Router>
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/crianca/:id" component={Kid} />
              <Route
                exact
                path="/apadrinhamento/:id/:nome-:sexo"
                component={Checkout}
              />
            </Switch>
          </ScrollToTop>
        </Router>
        <AppFooter>
          <div>
            <h3>Endereço</h3>
            <p>Rua Geraldo Vasconcelos 1220 - Estoril - Belo Horizonte – MG</p>
          </div>
          <div>
            <h3>Contatos</h3>
            <p>
              (31) 3373-4557 / (31) 98320-8016 - padrinhosreconstruir@gmail.com
            </p>
          </div>
          <div>
            <h3>Siga-nos</h3>
            <a href={LINK_INSTAGRAM} target="_blank">
              <Instagram width={25} fill="#fff" />
            </a>
            <a href={LINK_FACEBOOK} target="_blank">
              <Facebook width={25} fill="#fff" />
            </a>
            <a href={LINK_LINKEDIN} target="_blank">
              <Linkedin width={25} fill="#fff" />
            </a>
          </div>
        </AppFooter>
      </div>
    );
  }
}

export default App;
